import type {
  Database,
  Tables,
  TablesInsert,
  TablesUpdate
} from '~~/types/supabase'
import type { AtLeast } from '~~/types/utils'

type Product = Tables<'products'>
type ProductInsert = TablesInsert<'products'>
type ProductImage = Tables<'product_images'>
type Category = Tables<'product_categories'>

export type ProductWithImages = Product & {
  product_images: Array<Pick<ProductImage, 'id' | 'image_name' | 'image_order'>>
  product_categories: Pick<Category, 'name'>
}

export const useProducts = () => {
  const supabase = useSupabaseClient<Database>()
  const categories = ref<Category[]>([])
  const editingProduct = useState<ProductWithImages | null>(
    'editingProduct',
    () => null
  )

  // Function to get all products
  const getProducts = async ({
    categoryId,
    searchText
  }: { categoryId?: number; searchText?: string } = {}) => {
    let query = supabase
      .from('products')
      .select(
        '*, product_images(id, image_name, image_order), product_categories(name)'
      )

    if (categoryId) {
      query = query.eq('category_id', categoryId)
    }

    if (searchText) {
      query = query.or(
        `name.ilike.%${searchText}%, description.ilike.%${searchText}%`
      )
    }

    const { data, error } = await query.returns<ProductWithImages[]>()

    if (error) throw error

    return data.map(formatProduct)
  }

  // Function to get a single product by id
  const getProductById = async (id: number) => {
    const { data, error } = await supabase
      .from('products')
      .select(
        '*, product_images(image_name, image_order), product_categories(name)'
      )
      .eq('id', id)
      .returns<ProductWithImages[]>()
      .single()

    if (error) throw error

    return formatProduct(data)
  }

  // Function to get a list of products by id
  const getProductsById = async (ids: number[]) => {
    const { data, error } = await supabase
      .from('products')
      .select(
        '*, product_images(image_name, image_order), product_categories(name)'
      )
      .in('id', ids)
      .returns<ProductWithImages[]>()

    if (error) throw error

    return data.map(formatProduct)
  }

  const uploadImage = async (productName: string, image: File) => {
    const fileName = getSafeFileName(productName, image)

    const { error } = await supabase.storage
      .from('product_images')
      .upload(fileName, image)

    if (error) throw error

    return { fileName, mimeType: image.type }
  }

  const uploadImages = async (
    productId: number,
    productName: string,
    images: File[]
  ) => {
    const results = await Promise.allSettled(
      images.map(async file => await uploadImage(productName, file))
    )

    const errors = results.filter(result => result.status === 'rejected')

    if (errors.length) throw errors[0]?.reason

    const assetFiles = results
      .filter(result => result.status === 'fulfilled')
      .map(result => result.value)

    const { error: assetFilesError } = await supabase
      .from('product_images')
      .insert(
        assetFiles.map((file, i) => ({
          product_id: productId,
          image_order: i,
          image_name: file.fileName
        }))
      )

    if (assetFilesError) throw assetFilesError
  }

  // Function to create a new product
  const createProduct = async ({
    images,
    ...product
  }: ProductInsert & { images: File[] }) => {
    const { data, error } = await supabase
      .from('products')
      .insert(product)
      .select()
      .single()

    if (error) throw error

    await uploadImages(data.id, data.name, images)

    // Fetch and return the new product with processed images
    const { data: newProduct, error: fetchError } = await supabase
      .from('products')
      .select(
        '*, product_images(id, image_name, image_order), product_categories(name)'
      )
      .eq('id', data.id)
      .returns<ProductWithImages[]>()
      .single()

    if (fetchError) throw fetchError

    return newProduct
  }

  // Function to update a product
  const updateProduct = async (
    id: number,
    updates: TablesUpdate<'products'>,
    imageUpdates?: {
      newImages: File[]
      existingImages: { id: number; image_name: string; image_order: number }[]
    }
  ) => {
    // Update product details
    const { error: updateError } = await supabase
      .from('products')
      .update(updates)
      .eq('id', id)
      .select()
      .single()

    if (updateError) throw updateError

    // Handle image updates if provided
    if (imageUpdates) {
      // Delete removed images
      const existingImageIds = imageUpdates.existingImages.map(img => img.id)
      if (existingImageIds.length > 0) {
        const { error: deleteError } = await supabase
          .from('product_images')
          .delete()
          .eq('product_id', id)
          .not('id', 'in', `(${existingImageIds.join(',')})`)

        if (deleteError) throw deleteError
      } else {
        // If no existing images, delete all images for this product
        const { error: deleteAllError } = await supabase
          .from('product_images')
          .delete()
          .eq('product_id', id)

        if (deleteAllError) throw deleteAllError
      }

      // Update existing images order
      const updatePromises = imageUpdates.existingImages.map(image =>
        supabase
          .from('product_images')
          .update({ image_order: image.image_order })
          .eq('id', image.id)
      )
      await Promise.all(updatePromises)

      // Upload new images
      for (const [index, file] of imageUpdates.newImages.entries()) {
        const fileName = `${Date.now()}_${file.name}`
        const { error: uploadError } = await supabase.storage
          .from('product_images')
          .upload(`product_${id}/${fileName}`, file)

        if (uploadError) throw uploadError

        const { error: insertError } = await supabase
          .from('product_images')
          .insert({
            product_id: id,
            image_name: `product_${id}/${fileName}`,
            image_order: imageUpdates.existingImages.length + index + 1
          })

        if (insertError) throw insertError
      }
    }

    // Fetch and return the updated product with images
    const { data: updatedProduct, error: fetchError } = await supabase
      .from('products')
      .select(
        '*, product_images(id, image_name, image_order), product_categories(name)'
      )
      .eq('id', id)
      .returns<ProductWithImages[]>()
      .single()

    if (fetchError) throw fetchError

    return updatedProduct
  }

  // Function to delete a product
  const deleteProduct = async (id: number) => {
    const { error } = await supabase.from('products').delete().eq('id', id)

    if (error) throw error
  }

  // Function to get product images for a specific product
  const getProductImages = async (productId: number) => {
    const { data, error } = await supabase
      .from('product_images')
      .select('*')
      .eq('product_id', productId)
      .order('image_order', { ascending: true })

    if (error) throw error

    return data.map(formatProductImage)
  }

  function formatProduct<T extends ProductWithImages>(product: T): T {
    return {
      ...product,
      product_images: product.product_images.map(formatProductImage)
    }
  }

  function formatProductImage<
    T extends AtLeast<ProductImage, 'image_name' | 'image_order'>
  >(image: T): T {
    return {
      ...image,
      image_name: image.image_name
        ? getSupabasePrivateImgPath({
            bucket: 'product_images',
            object: image.image_name
          })
        : null
    }
  }

  // Function to get all categories
  const getAllCategories = async () => {
    const { data, error } = await supabase
      .from('product_categories')
      .select('*')
      .order('name')

    if (error) throw error

    return (categories.value = data)
  }

  // Function to get a single category by id
  const getCategoryById = async (id: number) => {
    const { data, error } = await supabase
      .from('product_categories')
      .select('*')
      .eq('id', id)
      .single()

    if (error) throw error

    return data
  }

  // Function to create a new category
  const createCategory = async (
    category: TablesInsert<'product_categories'>
  ): Promise<Category> => {
    const { data, error } = await supabase
      .from('product_categories')
      .insert(category)
      .select()
      .single()

    if (error) throw error

    await getAllCategories() // Refresh the categories list

    return data
  }

  // Function to update a category
  const updateCategory = async (
    id: number,
    updates: TablesUpdate<'product_categories'>
  ) => {
    const { data, error } = await supabase
      .from('product_categories')
      .update(updates)
      .eq('id', id)
      .select()
      .single()

    if (error) throw error

    await getAllCategories() // Refresh the categories list

    return data
  }

  // Function to delete a category
  const deleteCategory = async (id: number) => {
    const { error } = await supabase
      .from('product_categories')
      .delete()
      .eq('id', id)

    if (error) throw error

    await getAllCategories() // Refresh the categories list
  }

  // Helper function to get category name by id
  const getCategoryName = (categoryId: number) => {
    const category = categories.value.find(c => c.id === categoryId)

    return category?.name ?? 'Unknown Category'
  }

  return {
    getProducts,
    getProductById,
    getProductsById,
    createProduct,
    updateProduct,
    deleteProduct,
    getProductImages,
    getAllCategories,
    getCategoryById,
    createCategory,
    updateCategory,
    deleteCategory,
    getCategoryName,
    categories,
    editingProduct
  }
}
